.topbarContainer {
  height: 50px;
  width: 100%;
  background-color: #191414;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarLeft {
  flex: 3;
}

.logo {
  font-size: 24px;
  margin-left: 20px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.topbarLeft a {
  text-decoration: none;
}

.topbarCenter {
  flex: 5;
}

.searchbar {
  width: 100%;
  height: 30px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.searchIcon {
  font-size: 20px !important;
  margin-left: 10px;
}

.searchInput {
  border: none;
  width: 70%;
}

.searchInput:focus {
  outline: none;
}

.topbarRight {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
}

.topbarLink {
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.topbarIcons {
  display: flex;
}

.topbarIconItem {
  margin-right: 15px;
  cursor: pointer;
  position: relative;
}

.topbarIconBadge {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.topbarImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.topbarLinks {
  display: flex;
  align-items: center;
}

.dropdownContainer {
  position: relative;
  cursor: pointer;
  margin-left: 15px;
}

.dropdownMenu {
  position: absolute;
  top: 30px; /* Adjust based on your design */
  background-color: #333;
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownItem {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdownItem:hover {
  background-color: #575757;
}

/* Optionally add hover effect if you want the dropdown to show on hover */
.dropdownContainer:hover .dropdownMenu {
  display: block;
}


.logo-a img {
  width: 150px;
  margin-left: 60px;
}