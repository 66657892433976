.groupPageContainer {
  display: flex;
  padding: 20px;
  /* max-width: 1200px; */
  margin: 0 auto;
  background-color: black;
}

.sidebarContainer {
  flex: 1; 
  position: sticky;
  top: 50px; 
}

.groupContentContainer {
  flex: 3; 
  padding: 20px;
}

.pageTitle {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 30px;
  color:white;
}

.groupBoxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  justify-items: center;
}

.groupBox {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.groupBox:hover {
  transform: translateY(-10px);
}

.groupImage {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 2px solid #e0e0e0;
}

.groupTitle {
  font-size: 1.5rem;
  margin: 20px;
  color: #333;
}

.groupDescription {
  font-size: 1rem;
  color: #666;
  margin: 0 20px 20px;
  line-height: 1.4;
}

.joinButton {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.joinButton:hover {
  background-color: #0056b3;
}
