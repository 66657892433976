.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
  }
  
  .popup-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .popup-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-btn {
    background-color: #f44336;
    color: white;
  }
  
  .upgrade-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .upgrade-btn:hover {
    background-color: #45a049;
  }
  