.packagePage {
    text-align: center;
    padding: 40px;
    background-color: #f4f4f4;
  }
  
  .packagePageTitle {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
  }
  
  .packageCards {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .packageCard {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .packageCard h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #fff;
  }
  
  .price {
    font-size: 24px;
    font-weight: 500;
    color: #4CAF50;
    margin-bottom: 20px;
  }
  
  .packageCard ul {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .packageCard ul li {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .packageCard .btn {
    padding: 12px 30px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .packageCard .btn:hover {
    background-color: #45a049;
  }
  
  .packageCard.free {
    background-color: #191414;
    color: white;
  }
  
  .packageCard.advanced {
    background-color: #191414;
    color: white;
  }
  
  .packageCard.pro {
    background-color: #191414;
    color: white;
  }
  
  .packageCard:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  